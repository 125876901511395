import { useNavigate } from 'react-router-dom';
import Button from '../../components/ui/Button';

const ThankYouPage = () => {
  const navigate = useNavigate();

  return (
    <div className="thank-you-container d-flex flex-column justify-content-center align-items-center text-center">
      <div className="mb-4">
        <h4>
          <span>
            <img src="/images/icons/circle.png" className="mg-fluid" alt="tick icon" width="36" height="36" />
            <span> Order Successfully Placed</span>
          </span>
        </h4>
      </div>
      <h1><b>Your Order is Confirmed!</b></h1>
      <div className='mt-5'>
        <p>
          If you have questions about your order, you can email us at <br /> 
          <a href="mailto:pedal@virbike.com"><b>pedal@virbike.com</b></a> or call us at <a href="tel:+91-9766396579"><b>+91-9766396579</b></a>
        </p>
        <Button
          onClick={() => navigate('/')} 
          className="feature-btn mt-5"
          style={{'padding': '0.8rem 1.6rem', 'fontSize': '1.2rem'}}>
            Return to Home
        </Button>
      </div>
    </div>
  );
};

export default ThankYouPage;
