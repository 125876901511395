
import Seo from '../../components/common/Seo';

const PrivacyPolicy = () => {
  return (
    <>
      <Seo 
        title="VIR BIKE Privacy Policy - Your Information is Safe with Us"
        description="Understand how VIR BIKE protects your personal data and ensures your privacy. Review our policies to learn about data collection, storage, and usage practices."
        name="VIR MOBILITY PVT. LTD"
        type="webapp"
        />
      <section id="privacy-policy" className="section">
        <div className="privacy-policy-container container">
          <h1>Privacy Policy</h1>
          <p>Effective Date: </p>
          <p>VIR Mobility Private Limited ("VIR BIKE") is committed to safeguarding the privacy of users and customers of our products and services. This Privacy Policy outlines the methods by which we collect, use, process, and share personal data. By using our services, you agree to the practices described in this policy.</p>

          <div className="polices">
            <ol className="custom-o-list">
              <li className="custom-list">
                Collection of Personal Data
                <p>VIR BIKE collects personal information from users and customers in connection with the provision of our products and services. This information may include, but is not limited to:</p>
                <p>- Name</p>
                <p>- Gender</p>
                <p>- Address</p>
                <p>- Email address</p>
                <p>- Telephone number</p>
                <p>- Payment information</p>
                <p>- Browsing and usage history on our website</p>
                <p>The collection of personal data is necessary to fulfill contractual obligations and provide an optimal user experience.</p>
              </li>
              <li className="custom-list">
                Use of Personal Data
                <p>The personal data collected by VIR BIKE is used to:</p>
                <p>- Process and fulfill orders</p>
                <p>- Provide customer and technical support</p>
                <p>- Personalize user experiences</p>
                <p>- Conduct internal analytics and research</p>
                <p>- Send promotional or marketing communications (subject to user consent)</p>
                <p>- Prevent fraud and ensure security</p>

                <p>We process personal data as necessary for the performance of our services and in accordance with applicable legal obligations.</p>
              </li>
              <li className="custom-list">
                Sharing of Personal Data
                <p>VIR BIKE may share personal data with third parties in the following circumstances:</p>
                <p>- With service providers (e.g., payment processors, analytics providers, customer support providers) to help deliver our products and services.</p>
                <p>- For marketing and advertising purposes, in compliance with applicable privacy laws.</p>
                <p>- In the event of legal requirements or to enforce our rights, we may disclose personal data as required by law, court orders, or other legal processes.</p>

                <p>All third parties with whom we share data are obligated to maintain its confidentiality and security.</p>
              </li>
              <li className="custom-list">
                Data Security
                <p>VIR BIKE uses industry-standard security measures, including encryption, firewalls, and access controls, to safeguard personal data against unauthorized access, alteration, or disclosure. While we strive to protect your data, no system can guarantee absolute security.</p>
              </li>
              <li className="custom-list">
                Data Retention
                <p>VIR BIKE retains personal data for as long as necessary to fulfill the purposes for which it was collected, comply with legal obligations, resolve disputes, and enforce agreements. Personal data is deleted or anonymized once it is no longer required for these purposes.</p>
              </li>
              <li className="custom-list">
                Your Rights
                <p>As a data subject, you have the following rights regarding your personal data:</p>
                <p>- The right to access, update, or delete your personal data.</p>
                <p>- The right to restrict or object to the processing of your data.</p>
                <p>- The right to withdraw consent where consent is the legal basis for processing.</p>
                <p>- The right to lodge a complaint with a data protection authority if you believe your privacy rights have been violated.</p>

                <p>All third parties with whom we share data are obligated to maintain its confidentiality and security.</p>
                <p>To exercise any of these rights, please contact us at <strong><a href="mailto:pedal@virbike.com"> pedal@virbike.com</a></strong> or via our customer support number <strong><a href="tel:+91-9766396579">+91-9766396579</a></strong>.</p>
              </li>
              <li className="custom-list">
                Changes to This Privacy Policy
                <p>VIR BIKE reserves the right to modify this Privacy Policy at any time. Any changes will be effective upon posting the revised policy on our website. Users will be notified of any material changes to the policy through the website or other appropriate means.</p>
              </li>

              <li className="custom-list">
                Contact Information
                <p>
                For questions or concerns regarding this Privacy Policy, please contact us at:
                  Email: <strong><a href="mailto:pedal@virbike.com"> pedal@virbike.com</a></strong>                  
                </p>
                <p>Phone: <strong><a href="tel:+91-9766396579"> +91-9766396579</a></strong></p>
              </li>
            </ol>
          </div>
          <p className='mt-5'>Copyright © VIR Mobility Private Limited, All Rights Reserved.</p>
        </div>
      </section>
    </>
  )
}

export default PrivacyPolicy;